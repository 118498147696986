import { useDispatch } from 'react-redux';
import { errorActions } from '../../actions';
import { saveAs } from 'file-saver';
import { documentsService } from '../../services';
import { DocumentStoreType } from '../../types/document/Document';
import { useState } from 'react';
import { RequestState } from '../../constants/request-state';

export const useDownloadDocument = (documentStoreType: DocumentStoreType) => {
    const dispatch = useDispatch();
    const [requestStatusDownloadAll, setRequestStatusDownloadAll] = useState(RequestState.none);

    const downloadDocument = async (id: string) => {
        try {
            const document = await documentsService.downloadDocument(id, documentStoreType);
            saveAs(document.blob, document.name)
        } catch (e) {
            dispatch(errorActions.unexpectedError(e));
        }
    }

    const downloadAllDocuments = async (documentGroupKey: string) => {
        try {
            setRequestStatusDownloadAll(RequestState.request)
            const document = await documentsService.downloadAllDocuments(documentGroupKey, documentStoreType);
            saveAs(document.blob, document.name)
            setRequestStatusDownloadAll(RequestState.success)
        } catch (e) {
            dispatch(errorActions.unexpectedError(e));
            setRequestStatusDownloadAll(RequestState.failure)
        }
    }

    return { downloadDocument, downloadAllDocuments, requestStatusDownloadAll }
}
