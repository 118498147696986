import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import { errorActions as actions, errorActions } from '../../actions';
import { routes } from '../../constants';
import { logger } from '../../logging/logger';
import { history } from '../../history';

class Shield extends Component {
    componentDidCatch = error => this.props.dispatch(actions.criticalError(error));

    componentDidUpdate = () => {
        const { dispatch, error } = this.props;
        if (error && error.criticalError) {
            dispatch(actions.resetError());
        }
    }

    render = () => {
        const { criticalError, error = {} } = this.props.error;
        if (criticalError && !errorActions.isRequestCancelationError(error)) { // skip request cancelation errors
            logger.exception(error, 'Critical error');

            let pathname;
            switch (+error.status) {
                case 401: pathname = history.location.pathname === routes.login ? '' : routes.logout; break;
                case 403: pathname = routes.forbidden; break;
                case 404: pathname = routes.notFound; break;
                default: pathname = routes.internal; break;
            }

            if (pathname) {
                return <Redirect to={{ pathname }} />
            }
        }

        return this.props.children;
    }
}

const mapStateToProps = ({error}) => ({ error });

const connectedShield = connect(mapStateToProps)(Shield);

export { connectedShield as Shield };
