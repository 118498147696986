import { useEffect, useState } from "react";
import { useAppDispatch } from "../../effects/useAppDispatch";
import { RequestState } from "../../constants/request-state";
import { DealDocuments } from "../../types/document/DealDocuments";
import { isRequestNone } from "../../utils";
import { bwicDealsService } from "../../services/bwicDeals.service";

export function useDealDocuments(securityId: number) {
    const dispatch = useAppDispatch();

    const [requestState, setRequestState] = useState(RequestState.none);
    const [dealDocuments, setDealDocuments] = useState<DealDocuments | null>(null);

    useEffect(() => {
        if (isRequestNone(requestState)) {
            (async function doRequest() {
                setRequestState(RequestState.request)

                try {
                    const [dealDocuments] = await bwicDealsService.getDeals([securityId]);
                    setDealDocuments(dealDocuments);
                    setRequestState(RequestState.success)
                } catch {
                    setRequestState(RequestState.failure)
                }
            })();

        }
    }, [securityId, requestState, dealDocuments, dispatch])

    return { dealDocuments, requestState };
}