import cn from 'classnames';
import { keys } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik, useFormikContext } from 'formik';
import { uploadDocumentsActions as actions } from '../../actions';
import { documentLimits, errorMessages } from '../../constants';
import { FileIcon, FormError, PopupBody, PopupFooter } from '../controls';
import IconSVG from '../../styles/svg-icons';
import Popup from '../controls/Popup';
import { yup } from '../../validation/yup';
import { DealDocumentTypeState } from '../../types/state/UploadDocumentsState';

export const UploadAll = () => {
    const dispatch = useDispatch();
    const uploadAll = useSelector(state => state.uploadDocuments.uploadAll) ?? {};

    if(!uploadAll.visible || !uploadAll.deal) return null;

    const initialFormValues = {
        [DealDocumentTypeState.OM]: [],
        [DealDocumentTypeState.Indenture]: [],
        [DealDocumentTypeState.DistributionReport]: [],
        [DealDocumentTypeState.MonthlyReport]: [],
        [DealDocumentTypeState.Other]: []
    };

    const hasSelectedFiles = values =>
        values[DealDocumentTypeState.OM].length ||
        values[DealDocumentTypeState.Indenture].length ||
        values[DealDocumentTypeState.DistributionReport].length ||
        values[DealDocumentTypeState.MonthlyReport].length ||
        values[DealDocumentTypeState.Other].length

    const clearAndClose = () => dispatch(actions.uploadAllDialog(false));

    const validateFilesize = file => file.size > documentLimits.fileSizeLimit;

    const validateFileType = file => {
        const splitFile = (file.name || '').split('.');
        const fileExtension = splitFile[splitFile.length - 1];
        return documentLimits.fileTypePattern.split(',').some(ext => ext === `.${fileExtension.toLowerCase()}`)
    }

    const errorMessageFileSizeLimit = errorMessages.fileSizeLimitExceeded(documentLimits.fileSizeLimit / 1024 / 1024);
    const errorMessageWrongFileType = errorMessages.documentInvalidFileTypeTitle;

    const manyFilesValidationSchema = (documentType, currentCount) =>
        yup.mixed()
            .test(
                documentType,
                errorMessages.documentsUploadLimitExceeded(documentLimits.manyDocumentsUploadLimit),
                files => files.length <= documentLimits.manyDocumentsUploadLimit
            )
            .test(
                documentType,
                errorMessages.documentsLimitExceeded(documentLimits.documentsLimitPerType),
                files => files.length + currentCount <= documentLimits.documentsLimitPerType
            )
            .test(
                documentType,
                errorMessageFileSizeLimit,
                files => files.length ? !files.some(file => validateFilesize(file)) : true
            )
            .test(
                documentType,
                errorMessageWrongFileType,
                files => files.length ? !files.some(file => !validateFileType(file)) : true
            )

    const validationSchema = yup.object().shape({
        [DealDocumentTypeState.OM]: manyFilesValidationSchema(DealDocumentTypeState.OM,  uploadAll.deal.om?.length ?? 0),
        [DealDocumentTypeState.Indenture]: manyFilesValidationSchema(DealDocumentTypeState.Indenture, uploadAll.deal.indenture?.length ?? 0),
        [DealDocumentTypeState.DistributionReport]: manyFilesValidationSchema(DealDocumentTypeState.DistributionReport, uploadAll.deal.distributionReports?.length ?? 0),
        [DealDocumentTypeState.MonthlyReport]: manyFilesValidationSchema(DealDocumentTypeState.MonthlyReport, uploadAll.deal.monthlyReports?.length ?? 0),
        [DealDocumentTypeState.Other]: manyFilesValidationSchema(DealDocumentTypeState.Other, uploadAll.deal.other?.length ?? 0)
    })

    const handleSubmit = data => {
        clearAndClose();

        data[DealDocumentTypeState.OM]
            ?.forEach(file =>
                dispatch(actions.uploadDocument(
                    file, uploadAll.deal.referenceName, DealDocumentTypeState.OM))
            );

        data[DealDocumentTypeState.Indenture]
            ?.forEach(file =>
                dispatch(actions.uploadDocument(
                    file, uploadAll.deal.referenceName, DealDocumentTypeState.Indenture))
            );

        data[DealDocumentTypeState.DistributionReport]
            ?.forEach(file =>
                dispatch(actions.uploadDocument(
                    file, uploadAll.deal.referenceName, DealDocumentTypeState.DistributionReport))
            );

        data[DealDocumentTypeState.MonthlyReport]
            ?.forEach(file =>
                dispatch(actions.uploadDocument(
                    file, uploadAll.deal.referenceName, DealDocumentTypeState.MonthlyReport))
            );

        data[DealDocumentTypeState.Other]
            ?.forEach(file =>
                dispatch(actions.uploadDocument(
                    file, uploadAll.deal.referenceName, DealDocumentTypeState.Other))
            );
    }

    if (uploadAll.visible) {
        return (
            <Popup
                modalClass="modal-upload-all"
                onClose={clearAndClose}
                title="Upload Documents"
            >
                <Formik
                    initialValues={initialFormValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ values, errors }) => {
                        const isUploadButtonDisabled = !hasSelectedFiles(values) || Boolean(keys(errors).length);

                        return (
                            <Form noValidate={true}>
                                <PopupBody>
                                    <div className="bwic-info">
                                        <p className="text-medium">{uploadAll.deal.name}</p>
                                        <p className="text-warm-grey">Tranches: {uploadAll.deal.tranches.join(', ')}</p>
                                        <p className="text-warm-grey text-sm">
                                            The maximum number of files per upload is {documentLimits.manyDocumentsUploadLimit} for each type, with a maximum file size of {documentLimits.fileSizeLimit / 1024 / 1024}MB per file.
                                        </p>
                                    </div>
                                    <ManyDocumentsUpload title="OM" documentType={DealDocumentTypeState.OM} />
                                    <ManyDocumentsUpload title="Indenture" documentType={DealDocumentTypeState.Indenture} />
                                    <ManyDocumentsUpload title="Distribution Reports" documentType={DealDocumentTypeState.DistributionReport} />
                                    <ManyDocumentsUpload title="Monthly Reports" documentType={DealDocumentTypeState.MonthlyReport} />
                                    <ManyDocumentsUpload title="Other Files" documentType={DealDocumentTypeState.Other} />
                                </PopupBody>
                                <PopupFooter>
                                    <button className="btn btn-ghost" type="button" onClick={clearAndClose}>
                                        CANCEL
                                    </button>
                                    <button className="btn btn-main" disabled={isUploadButtonDisabled} type="submit">
                                        UPLOAD ALL
                                    </button>
                                </PopupFooter>
                            </Form>
                        )
                    }}
                </Formik>
            </Popup>
        )
    }

    return null;
}

function ManyDocumentsUpload({ documentType, title }) {
    const { values, errors, setFieldValue, submitCount } = useFormikContext();

    const uid = `${documentType}File`;

    return (
        <div className="form-row">
            <label className="form-label">{title}</label>
            <div className="form-control-wrapper">
                <label
                    className={cn('form-control form-control-uploadF', { 'active-color': values[documentType].length })}
                    htmlFor={uid}
                >
                    Browse Source
                </label>
                <input
                    id={uid}
                    name={uid}
                    className={
                        cn('form-control-upload', { 'is-invalid': submitCount > 0 && !!errors[documentType] })
                    }
                    type="file"
                    multiple
                    onChange={e =>
                        setFieldValue(documentType, [...values[documentType], ...e.target.files])
                    }
                    accept={documentLimits.fileTypePattern}
                />
                <FormError message={submitCount > 0 && errors[documentType]} />
            </div>
            {
                !!values[documentType].length &&
                <div className="form-row row-files">
                    {values[documentType].map((d, i) =>
                        <div className="flex-row flex-row-multifiles" key={`${d.name}_${i}`}>
                            <FileIcon filename={d.name} />
                            <span className="file-name text-sm">{d.name}</span>
                            <button
                                className="btn-close flex-none flex-item-right"
                                onClick={() => {
                                    const files = [...values[documentType]]
                                    files.splice(i, 1)
                                    setFieldValue(documentType, files)
                                }}
                            >
                                <IconSVG name="close" width={16} height={16} />
                            </button>
                        </div>
                    )}
                </div>
            }
        </div>
    );
}