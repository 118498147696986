import { Component } from 'react'
import { connect } from 'react-redux';
import { uploadDocumentsActions as actions } from '../../actions';
import { FileIcon } from '../controls';
import { DealDocumentTypeState } from '../../types/state/UploadDocumentsState';

class AddDocument extends Component {
    render = () => {
        const { documentType, dealReferenceName, dispatch } = this.props;

        return (
            <div className="cell-item-file btn-link" onClick={() => dispatch(actions.uploadDialog(true, dealReferenceName, documentType))}>
                <FileIcon isNew={true} />
                <span className="file-add-title">{this.getTitle(documentType)}</span>
            </div>
        );
    }

    getTitle = documentType => {
        switch (documentType) {
            case DealDocumentTypeState.OM: return 'Add OM';
            case DealDocumentTypeState.Indenture: return 'Add Indenture';
            case DealDocumentTypeState.MonthlyReport: return 'Add Monthly Report';
            case DealDocumentTypeState.DistributionReport: return 'Add Distribution Report';
            default: return 'Add File';
        }
    }
}

const connectedAddDocument = connect()(AddDocument);
export { connectedAddDocument as AddDocument };
