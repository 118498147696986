import { uploadDocumentsActions } from "../../actions";
import { documentLimits, SORTING_TYPE } from "../../constants";
import { useAppDispatch } from "../../effects/useAppDispatch";
import { useAppSelector } from "../../effects/useAppSelector";
import { DealDocuments } from "../../types/document/DealDocuments";
import { Document } from "../../types/document/Document";
import { DealDocumentTypeState } from "../../types/state/UploadDocumentsState";
import { Table } from "../bidding/common/table";
import { ColumnBuilder } from "../bidding/common/table/columns/column-builder/ColumnBuilder";
import { IColumnDefinition } from "../bidding/common/table/types/ColumnDefinition";
import { OnHoverTooltip, Preloader } from "../common";
import { AddDocument } from "./AddDocument";
import { Document as DocumentComponent } from "./Document";
import { UploadAll } from "./UploadAll";
import { UploadSingle } from "./UploadSingle";

export function ManageDealDocuments() {
    const dispatch = useAppDispatch();

    const isInitialized = useAppSelector(s => s.uploadDocuments.isInitialized);
    const deals = useAppSelector(s => s.uploadDocuments.deals);

    const columns: IColumnDefinition<DealDocuments>[] = [{
        columnKey: "deals-and-tranches",
        renderColumnHeaderContent: () => 'Deals and Tranches',
        renderColumnContent: deal => (
            <OnHoverTooltip overlay={deal.name}>
                <span className="text-ellipsis display-inline text-medium">
                    {deal.name}
                </span>
                <span className="text-sm">{deal.tranches.join(', ')}</span>
            </OnHoverTooltip>
        ),
        className: "data-list-cell-xl-xxl cell-upload-all",
        sortingField: "name",
        sortingType: SORTING_TYPE.string
    }, {
        columnKey: "upload-all",
        renderColumnHeaderContent: () => '',
        renderColumnContent: deal => <button className="btn btn-link" onClick={() => dispatch(uploadDocumentsActions.uploadAllDialog(true, deal))}>UPLOAD ALL</button>,
        className: ''
    }, {
        columnKey: "om-docs",
        renderColumnHeaderContent: () => 'OM',
        renderColumnContent: deal => <MultipleDocumentCellContent dealReferenceName={deal.referenceName} documentType={DealDocumentTypeState.OM} documents={deal.om} />,
        className: 'data-list-cell-lg-02'
    }, {
        columnKey: "indenture-docs",
        renderColumnHeaderContent: () => 'Indenture',
        renderColumnContent: deal => <MultipleDocumentCellContent dealReferenceName={deal.referenceName} documentType={DealDocumentTypeState.Indenture} documents={deal.indenture} />,
        className: 'data-list-cell-lg-02'
    }, {
        columnKey: "distribution-report-docs",
        renderColumnHeaderContent: () => 'Distribution Reports',
        renderColumnContent: deal => <MultipleDocumentCellContent dealReferenceName={deal.referenceName} documentType={DealDocumentTypeState.DistributionReport} documents={deal.distributionReports} />,
        className: 'data-list-cell-lg-02'
    }, {
        columnKey: "monthly-report-docs-02",
        renderColumnHeaderContent: () => 'Monthly Reports',
        renderColumnContent: deal => <MultipleDocumentCellContent dealReferenceName={deal.referenceName} documentType={DealDocumentTypeState.MonthlyReport} documents={deal.monthlyReports} />,
        className: 'data-list-cell-lg-02'
    }, {
        columnKey: "other-docs",
        renderColumnHeaderContent: () => 'Other',
        renderColumnContent: deal => <MultipleDocumentCellContent dealReferenceName={deal.referenceName} documentType={DealDocumentTypeState.Other} documents={deal.other} />,
        className: 'data-list-cell-xl-flexible'
    }];

    return (
        <Preloader inProgress={!deals || !isInitialized}>
            <Table
                className="data-list-striped documents-table"
                dataItems={deals}
                columns={columns.map(c => new ColumnBuilder(c))}
                defaultSortBy="name"
            />
            <UploadSingle />
            <UploadAll />
        </Preloader>
    );
}

interface MultipleDocumentCellContentProps {
    dealReferenceName: string;
    documentType?: DealDocumentTypeState;
    documents?: Document[];
}

function MultipleDocumentCellContent({ dealReferenceName, documentType, documents }: MultipleDocumentCellContentProps) {
    const state = useAppSelector(s => s.uploadDocuments.uploadingDocuments);

    const uploadingState =
        state.filter(x =>
            x.dealReferenceName === dealReferenceName &&
            x.documentType === documentType
        );

    return (
        <>
            {
                documents?.map(d =>
                    <DocumentComponent
                        key={d.id}
                        document={d}
                        documentType={documentType}
                        uploading={uploadingState.find(u => u.currentDocumentId === d.id)}
                        dealReferenceName={dealReferenceName} />)
            }
            {
                uploadingState
                    .filter(d => !d.currentDocumentId)
                    .map(d =>
                        <DocumentComponent
                            key={d.uid}
                            uploading={d}
                            dealReferenceName={dealReferenceName}
                        />)
            }
            {
                !uploadingState?.length &&
                (!documents?.length || documents?.length < documentLimits.documentsLimitPerType) &&
                <AddDocument documentType={documentType} dealReferenceName={dealReferenceName} />
            }
        </>
    );
}