import IconSVG from '../../styles/svg-icons';
import { SubscriptionFeature } from '../../types/billing/SubscriptionFeature';
import { bwicService } from '../../services';
import { Preloader } from '../common';
import { useDownloadDocument } from '../../effects/useDownloadDocument';

interface Props {
    isinCusip: string;
    textVisible?: boolean;
    text?: string;
    requiredFeature?: SubscriptionFeature;
    disabled?: boolean;
}

export function DownloadDocuments({ isinCusip, textVisible = true, disabled, text = 'Docs' }: Props) {
    const { handleDownload, isDownloading } = useDownloadDocument({
        downloadRequest: bwicService.getSecurityDocsZip,
        requestArgs: [isinCusip],
        fileNameForSave: 'SecurityDocs.zip'
    });

    return (
        <Preloader small fullScreen={false} inProgress={isDownloading} text="Downloading...">
            <button
                className="btn-link link-download-docs"
                type="button"
                disabled={disabled}
                onClick={handleDownload}
            >
                <IconSVG name="downloadTemplate" width={16} height={16} />
                {textVisible && <span>{text}</span>}
            </button>
        </Preloader>
    );
}
