import { useState } from 'react';
import { RequestState } from '../../constants/request-state';
import { useDispatch } from 'react-redux';
import { errorActions } from '../../actions';
import { documentsService } from '../../services';
import { DocumentStoreType } from '../../types/document/Document';

export const useUploadDocument = (documentStoreType: DocumentStoreType) => {
    const dispatch = useDispatch();
    const [requestStateSaveDocument, setRequestStateSaveDocument] = useState(RequestState.none);
    const [progress, setProgress] = useState<{[fileName: string]: number}>({});

    const onUploadDocument = async (file: File) => {
        setRequestStateSaveDocument(RequestState.request)
        try {
            const response = await documentsService.uploadDocument(
                file,
                (progressEvent: ProgressEvent) => setProgress((progress) => ({
                    ...progress,
                    [file.name]: Math.floor((progressEvent.loaded / progressEvent.total) * 100)
                })),
                documentStoreType
            );
            setRequestStateSaveDocument(RequestState.success)
            return response[0];
        } catch (e) {
            dispatch(errorActions.unexpectedError(e));
            setRequestStateSaveDocument(RequestState.failure)
        }
    }

    return {
        requestStateSaveDocument,
        onUploadDocument,
        progress,
    }
}
