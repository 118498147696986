import React from 'react';
import { Link } from 'react-router-dom';
import { routes } from '../../constants';
import image404 from '../../images/errorPages/404.svg';

export const NotFound = () => (
    <div className="container-error">
        <div className="content-error">
            <div className="error-type-block">
                <img src={image404} width="530" height="300" alt="404" />
            </div>
            <h1>Error: Page not found</h1>
            <p className="error-description">
                Sorry, the page you are looking for might have been removed, had its name changed or is
                temporarily unavailable.
            </p>
            <p className="error-links">
                Either check the URL, <Link to={routes.root}>go home</Link>, or feel free to <a href={`mailto:${process.env.REACT_APP_SALES_EMAIL}`}>report the issue</a>.
            </p>
        </div>
    </div>
);
