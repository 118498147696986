import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from "react-router";
import { saveAs } from 'file-saver';
import { documentsService } from '../../services/documents.service';
import { errorActions } from '../../actions';

const useDownloadDocument = (documentName: string) => {
    const dispatch = useDispatch();

    React.useEffect(() => {
        const download = async () => {
            try {
                const document = await documentsService.downloadStatic(documentName);
                saveAs(document.blob, document.name)
            } catch (e) {
                dispatch(errorActions.unexpectedError(e));
            }
        }

        download();

    }, [documentName, dispatch]);
}

export function DownloadDocument() {
    const { documentName } = useParams<{ documentName: string }>();
    useDownloadDocument(documentName);

    return null;
}
