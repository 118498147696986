import { Component } from 'react'
import { connect } from 'react-redux';
import { uploadDocumentsActions as actions } from '../../actions';
import { FileIcon, ProgressCircle } from '../controls';

class Document extends Component {
    renderDocument = () => {
        const { dealReferenceName, document, dispatch, deletingDocuments } = this.props;

        return (
            <div className="cell-item-file">
                <FileIcon filename={document.name} />
                <div className="file-description text-ellipsis" onClick={() => dispatch(actions.downloadDocument(document))}>{document.name}</div>
                <button
                    className="btn-link btn-danger"
                    onClick={() => dispatch(actions.deleteDocument(dealReferenceName, document.id))}
                    disabled={deletingDocuments[document.id]}
                >
                    <i className="icon icon-delete" />
                </button>
            </div>
        );
    };

    renderUploading = () => {
        const { uploading } = this.props;
        return (
            <div className="cell-item-file">
                <FileIcon filename={uploading.name} />
                <div className="file-description text-ellipsis">{uploading.name}</div>
                <ProgressCircle progress={uploading.progress} />
            </div>
        );
    };

    render = () => this.props.uploading ? this.renderUploading() : this.renderDocument();
}

const mapStateToProps = ({ uploadDocuments }) => ({
    refresh: uploadDocuments.refreshFlag,
    deletingDocuments: uploadDocuments.deletingDocuments,
});

const connectedDocument = connect(mapStateToProps)(Document);
export { connectedDocument as Document };
