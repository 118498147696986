import { useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import cn from 'classnames';
import { FormError, Popup } from '../controls';
import { documentLimits, errorMessages } from '../../constants';
import { uploadDocumentsActions as actions, formsActions } from '../../actions';
import { AppState } from '../../types/state/AppState';
import { yup } from '../../validation/yup';
import { keys } from 'lodash';
import { useAppDispatch } from '../../effects/useAppDispatch';

export const UploadSingle = () => {
    const dispatch = useAppDispatch();

    const upload = useSelector((state: AppState) => state.uploadDocuments.upload) || {};

    if (!upload.visible) {
        return null;
    }

    const handleFileUploadDialogClose = () => {
        dispatch(formsActions.reset());
        dispatch(actions.uploadDialog(false));
    }

    const handleSingleDocumentUpload = ({file}: {file: File | null}) => {
        if (file) {
            dispatch(actions.uploadDialog(false));
            dispatch(
                actions.uploadDocument(
                    file,
                    upload.dealReferenceName,
                    upload.documentType,
                )
            );
        }
    }

    const validateFilesize = (file: File) => file.size > documentLimits.fileSizeLimit;

    const validateFileType = (file: File) => {
        const splitFile = (file.name || '').split('.');
        const fileExtension = splitFile[splitFile.length - 1];
        return documentLimits.fileTypePattern.split(',').some(ext => ext === `.${fileExtension.toLowerCase()}`)
    }

    const initialFormValues: {file: File | null} = {
        file: null,
    }

    const validationSchema = yup.object().shape({
        file: yup.mixed<File>()
            .test(
                'file',
                errorMessages.fileSizeLimitExceeded(documentLimits.fileSizeLimit / 1024 / 1024),
                (file) => file ? !validateFilesize(file) : true
            )
            .test(
                'file',
                errorMessages.documentInvalidFileTypeTitle,
                (file) => file ? validateFileType(file) : true
            ),
    })

    return (
        <Popup title="Upload File" onClose={handleFileUploadDialogClose}>
            <Formik
                initialValues={initialFormValues}
                validationSchema={validationSchema}
                onSubmit={handleSingleDocumentUpload}
            >
                {({ errors, touched, setFieldValue, values, submitCount }) => {
                    return (
                        <Form noValidate={true}>
                            <div className="modal-body">
                                <label className="form-label">Select File <span className="text-red">*</span></label>
                                <div className="form-control-wrapper">
                                    <label
                                        className={cn('form-control form-control-uploadF', {'active-color': values.file})}
                                        htmlFor="file">{values.file ? values.file.name : 'Browse Source'}
                                    </label>
                                    <input
                                        id="file"
                                        name="file"
                                        className={cn("form-control-upload", {'is-invalid': submitCount > 0 && errors.file})}
                                        type="file"
                                        accept={documentLimits.fileTypePattern}
                                        onChange={e => {
                                            setFieldValue('file', e.target && e.target.files && e.target.files[0])
                                        }}
                                    />
                                    <FormError message={submitCount > 0 && errors.file} />
                                </div>
                                <span className="text-sm text-warm-grey">Max size {documentLimits.fileSizeLimit/1024/1024}MB</span>
                            </div>
                            <div className="modal-footer">
                                <button
                                    className="btn btn-ghost"
                                    type="button"
                                    onClick={handleFileUploadDialogClose}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="btn btn-main"
                                    type="submit"
                                    disabled={!values.file || !!(submitCount > 0 && keys(errors).length)}
                                >
                                    Upload
                                </button>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </Popup>
    );
}
